// import DatePicker from './datepicker/';

// export default defineNuxtPlugin((nuxtApp) => {
//   nuxtApp.vueApp.use(DatePicker);
// });

import DatePicker from './datepicker/components/DatePicker.vue';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('DatePicker', DatePicker);
});
