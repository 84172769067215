import { createHttpLink, from } from '@apollo/client/core';
// import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { provideApolloClient } from '@vue/apollo-composable';

export default defineNuxtPlugin((nuxtApp) => {
  // Get Nuxt runtimeConfig and apollo instance
  const { $apollo, $i18n } = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();
  const api = runtimeConfig.public.admin_base + '/graphql' || 'https://admin.www.ngprague.cz/graphql';

  // Create custom links (auth, error, http...)

  // Create an linkWithDynamicHeader and set authentication token if necessary
  const linkWithDynamicHeader = setContext(async (_, { headers }) => {
    return {
      headers: { 'Content-Language': $i18n.locale.value },
    };
  });

  const httpLink = linkWithDynamicHeader.concat(
    createHttpLink({
      // uri: runtimeConfig.public.apiGraphqlUrl,
      uri: api,
    })
  );

  const errorLink = onError((err) => {
    nuxtApp.callHook('apollo:error', err);
  });

  // Set custom links in the apollo client (in this case, the default apollo client)
  $apollo.defaultClient.setLink(from([errorLink, httpLink]));
  provideApolloClient(useApollo().clients.default);

  nuxtApp.hook('apollo:error', (error) => {
    console.error(error);
  });
});
