import { defineStore } from 'pinia';

export const useEventsStore = defineStore('eventsStore', {
  state: () => ({
    activeDropDownItem: null,
    dropDownIsOpen: false,
    activeTabIndex: 0,
    activeTabByDate: { id: 0 },
    activeTabByDateString: null,
    activeTabItem: null,
  }),
  actions: {
    setDropDownItem(value) {
      this.activeDropDownItem = value;
    },
    closeDropDown() {
      this.dropDownIsOpen = false;
    },
    toggleDropDown() {
      this.dropDownIsOpen = !this.dropDownIsOpen;
    },
    setActiveTabIndex(value) {
      this.activeTabIndex = value;
    },
    setActiveTabItem(value) {
      this.activeTabItem = value;
    },
    setActiveTabByDate(value) {
      this.activeTabByDate = value;
    },
    setActiveTabByDateString(value) {
      this.activeTabByDateString = value;
    },
  },
});
