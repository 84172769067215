<template>
  <div class="datepicker" v-click-outside="onClickOutside">
    <div class="datepicker__toggler" @click="toggle" @keyup.enter="toggle">
      <!-- <div class="tabs__item">
        <button class="tabs__button datepicker__button">{{ t('global.date-range.select-days') }}</button>
      </div> -->
      <TabButton :item="item" :id="id"></TabButton>
    </div>
    <div ref="mainRef" class="datepicker__main" :class="{ 'is-active': isActive, 'align-right': alignRight }">
      <div class="datepicker__inner">
        <button class="datepicker__increment-button datepicker__increment-button--prev" title="Previous Month" @click="changeDate(-1, 'months')" @keyup.enter="changeDate(-1, 'months')">
          <i class="icon-prev" />
          <span class="hidden">{{ $t('global.datepicker.previous-month') }}</span>
        </button>
        <div class="datepicker__panes">
          <div v-for="(pane, key) in panes" :key="key" class="datepicker__pane">
            <Pane :date="moment(visibleStartDate).add(key, 'months')" :selected-dates="selectedDates" :focused-date="focusedDate" />
          </div>
        </div>
        <button class="datepicker__increment-button datepicker__increment-button--next" title="Next Month" @click="changeDate(1, 'months')" @keyup.enter="changeDate(1, 'months')">
          <i class="icon-next" />
          <span class="hidden">{{ $t('global.datepicker.next-month') }}</span>
        </button>
        <div class="datepicker__footer">
          <button class="datepicker__cancel" @click="cancel" @keyup.enter="cancel">
            {{ $t('global.datepicker.cancel') }}
          </button>
          <button class="button button--small" :disabled="selectedDates.length < 2" @click="apply" @keyup.enter="apply">
            {{ $t('global.datepicker.apply') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeUnmount, computed } from 'vue';
import moment from 'moment';
import { sortDates } from '../utils';
import Pane from './Pane.vue';

const { locale, t } = useI18n();
const store = useEventsStore();
const { setActiveTabByDateString, setActiveTabByDate } = store;

const props = defineProps({
  startDate: {
    type: Date,
    default: () => new Date(),
  },
  item: { type: Object },
  id: { type: Number },
});

const panes = ref(2);
const { screenWidth } = useScreenWidthListener();
watch(screenWidth, () => {
  calculatePanes(screenWidth.value);
});

const calculatePanes = (width) => {
  if (width < 1200) {
    return (panes.value = 1);
  } else {
    return (panes.value = 2);
  }
};

const mainRef = ref(null);
const isActive = ref(false);
const visibleStartDate = ref(moment(props.startDate));
const selectedDates = ref([]);
const focusedDate = ref(null);
const value = ref(null);
const alignRight = ref(false);

onMounted(() => {
  window.addEventListener('keyup', onKeyUp);
});

onBeforeUnmount(() => {
  window.removeEventListener('keyup', onKeyUp);
});

const onClickOutside = (event) => {
  if (!mainRef.value?.contains(event.target)) {
    close();
  }
};

const onKeyUp = (e) => {
  if (e.key === 'Escape') {
    close();
  }
};

const apply = () => {
  value.value = selectedDates.value;
  transformDates(selectedDates.value);
  // transformDates(selectedDates.value);
  // emit('input', selectedDates.value);
  close();
};

const cancel = () => {
  selectedDates.value = value.value || [];
  focusedDate.value = null;
  setActiveTabByDateString(null);
  setActiveTabByDate(null);
  close();
};

const toggle = () => {
  isActive.value = !isActive.value;
  if (isActive.value) {
    const { left, width } = mainRef.value.getBoundingClientRect();
    alignRight.value = left + width >= window.innerWidth;
  } else {
    alignRight.value = false;
  }
};

const close = () => {
  isActive.value = false;
  alignRight.value = false;
};

const changeDate = (increment, units) => {
  visibleStartDate.value = moment(visibleStartDate.value).add(increment, units);
};

const dayFocus = (date) => {
  focusedDate.value = moment(date);
};

const transformDates = (dates) => {
  let transformedDates = [];
  if (dates.length === 2) {
    dates?.map((date) => {
      transformedDates.push(date.format('YYYY-MM-DD'));
    });
    transformedDates = transformedDates.join('|');
    setActiveTabByDateString(transformedDates);
    setActiveTabByDate(props.item);
  }
};

const dayClick = (date) => {
  let length = selectedDates.value.length;
  date = moment(date);

  if (!length) {
    selectedDates.value.push(date);
    // transformDates(selectedDates.value);
  } else if (length === 1) {
    if (selectedDates.value[0].isSame(date, 'day')) {
      selectedDates.value = [];
      // transformDates(selectedDates.value);
    } else {
      selectedDates.value = sortDates([selectedDates.value[0], date]);
      // transformDates(selectedDates.value);

      if (selectedDates.value[0].isBefore(visibleStartDate.value, 'month')) {
        visibleStartDate.value = moment(visibleStartDate.value).subtract(1, 'month');
      } else if (selectedDates.value[1].isAfter(moment(visibleStartDate.value).add(panes.value - 1, 'months'), 'month')) {
        visibleStartDate.value = moment(visibleStartDate.value).add(1, 'month');
      }
    }
  } else if (length === 2) {
    selectedDates.value = [date];
    transformDates(selectedDates.value);
  }
};

provide('datepicker-dayClick', dayClick);
provide('datepicker-dayFocus', dayFocus);
</script>

<style lang="scss">
.datepicker {
  position: static;
  user-select: none;

  &__toggler {
    display: inline-block;
  }

  &__main {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    transform: translateY(10px);
    padding: 25px 20px 20px;
    border: 1px solid $black;
    background: $white;
    opacity: 0;
    user-select: none;
    pointer-events: none;

    &.align-right {
      left: auto;
      right: 0;
    }

    &.is-active {
      opacity: 1;
      user-select: auto;
      pointer-events: all;
    }
  }

  &__inner {
    position: relative;
  }

  &__increment-button {
    @include button-reset;
    position: absolute;
    top: -3px;
    padding: 3px 10px;
    font-size: 14px;
    cursor: pointer;

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  &__panes {
    display: inline-flex;
    margin: 0 -16px;
  }

  &__pane {
    padding: 0 16px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }

  &__cancel {
    @include button-reset;
    text-decoration: underline;
    cursor: pointer;

    @include mouse-hover {
      text-decoration: none;
    }
  }
}
</style>
