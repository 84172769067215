import { gsap } from 'gsap';

let hpTransitioning = false;

let nothing = () => {
  return gsap.timeline();
};

let hpEnter = () => {
  hpTransitioning = true;

  if (window.innerWidth >= 992) {
    const percentageWidth = window.innerWidth >= 1200 ? '60%' : '50%';

    return gsap
      .timeline()
      .fromTo('.sidebar-logo__photo', 1 / 4, { width: '57%' }, { width: '0%' }, 0)
      .set('.sidebar-logo', { maxHeight: '100%' })
      .set('.sidebar-logo__image', { height: '100%' })
      .fromTo('.main:first-child', 1 / 4, { opacity: 0 }, { opacity: 0 }, 0)
      .fromTo('.sidebar', 1, { width: '375px' }, { width: percentageWidth, ease: 'power2.inOut' }, 0)
      .fromTo('.content', 1, { paddingLeft: '375px' }, { paddingLeft: percentageWidth, ease: 'power2.inOut' }, 0)
      .to('.main ', 1, { backgroundColor: '#ffffff', ease: 'power2.inOut' }, 0)
      .fromTo('.sidebar-bg__image-wrapper', 1, { opacity: 0 }, { opacity: 1, ease: 'power2.inOut' }, 0);
  } else {
    return gsap.timeline();
  }
};

let hpAfterEnter = () => {
  hpTransitioning = false;

  return gsap
    .timeline()
    .fromTo('.main:first-child', 1 / 4, { opacity: 0 }, { opacity: 1 }, 0)
    .fromTo('.footer', 1 / 4, { opacity: 0 }, { opacity: 1 }, 0.4)

    .fromTo('.sidebar-logo__photo', 1 / 4, { width: '0%' }, { width: '57%' }, 1);
};

let hpBeforeLeave = () => {
  if (window.innerWidth >= 992) {
    const percentageWidth = window.innerWidth >= 1200 ? '60%' : '50%';

    return gsap
      .timeline()
      .set('.sidebar', { width: percentageWidth })
      .set('.content', { paddingLeft: percentageWidth })
      .set('.sidebar-logo', { maxHeight: '100%' })
      .set('.sidebar-logo__image', { height: '100%' });
  } else {
    return gsap.timeline();
  }
};

let hpLeave = () => {
  if (window.innerWidth >= 992) {
    const percentageWidth = window.innerWidth >= 1200 ? '60%' : '50%';

    let mainBg = 'white';
    const mainTime = 1;
    const halfTime = mainTime / 4;

    if (document.querySelector('.featured-items') && document.querySelector('.featured-items').getAttribute('data-main-bg')) {
      mainBg = document.querySelector('.featured-items').getAttribute('data-main-bg');
    }

    return gsap
      .timeline()
      .set('.main:nth-of-type(1)', { zIndex: 1 })
      .set('.main:nth-of-type(2)', { position: 'absolute', top: 0, opacity: 1, width: window.innerWidth - 375 + 'px' })
      .set('.footer', { opacity: 0 })
      .set('.sidebar-logo__photo', { width: '0%' })
      .fromTo('.sidebar', mainTime, { width: percentageWidth }, { width: '375px', ease: 'power2.inOut' })
      .fromTo('.content', mainTime, { paddingLeft: percentageWidth }, { paddingLeft: '375px', ease: 'power2.inOut' }, 0)
      .fromTo('.main:nth-of-type(1)', mainTime, { backgroundColor: '#ffffff' }, { backgroundColor: mainBg, ease: 'power2.inOut' }, 0)
      .fromTo('.home', halfTime, { opacity: 1 }, { opacity: 0, ease: 'power2.inOut' }, 0)
      .set('.main:nth-of-type(2)', { zIndex: 2 }, 0)
      .fromTo('.main:nth-of-type(2)', halfTime, { opacity: 0 }, { opacity: 1, ease: 'power2.inOut' }, halfTime)
      .fromTo('.sidebar-bg__image-wrapper', mainTime, { opacity: 1 }, { opacity: 0, ease: 'power2.inOut' }, 0);
  } else {
    return gsap.timeline();
  }
};

let hpAfterLeave = () => {
  if (window.innerWidth >= 992) {
    return gsap
      .timeline()
      .set('.main', { position: 'relative' })
      .set('.footer', { opacity: 1 })
      .set('.sidebar-logo', { maxHeight: '200px' })
      .set('.sidebar-logo__image', { height: 'auto' })
      .fromTo('.sidebar-logo__photo', 1 / 4, { width: '0%' }, { width: '57%' }, 0);
  } else {
    return gsap.timeline();
  }
};

let pageLeave = () => {
  if (window.innerWidth >= 992 && !hpTransitioning) {
    return gsap
      .timeline()
      .fromTo('.main:first-child', 1 / 4, { opacity: 1 }, { opacity: 0 }, 0)
      .fromTo('.footer', 1 / 4, { opacity: 1 }, { opacity: 0 }, 0.4);
  } else {
    return gsap.timeline();
  }
};

let pageEnter = () => {
  if (window.innerWidth >= 992) {
    return gsap
      .timeline()
      .set('.main:first-child', { opacity: 0 })
      .fromTo('.main:first-child', 1 / 4, { opacity: 0 }, { opacity: 1 }, 0)
      .fromTo('.footer', 1 / 4, { opacity: 0 }, { opacity: 1 }, 0.4);
  } else {
    return gsap.timeline();
  }
};

const homepageTransition = {
  name: 'homepage-transiton',
  mode: 'out-in',
  onBeforeLeave(el, done) {
    let beforeLeaveTimeline = gsap.timeline();
    beforeLeaveTimeline.add(hpBeforeLeave());
    beforeLeaveTimeline.eventCallback('onComplete', done);
  },
  onLeave(el, done) {
    let leaveTimeline = gsap.timeline();
    leaveTimeline.add(hpLeave());
    leaveTimeline.eventCallback('onComplete', done);
  },
  onAfterLeave(el, done) {
    let afterLeaveTimeline = gsap.timeline();
    afterLeaveTimeline.add(hpAfterLeave());
    afterLeaveTimeline.eventCallback('onComplete', done);
  },
  // onBeforeEnter(el, done) {
  //   let enterTimeline = gsap.timeline();
  //   enterTimeline.add(hpBeforeEnter());
  //   enterTimeline.eventCallback('onComplete', done);
  // },
  onEnter(el, done) {
    let enterTimeline = gsap.timeline();
    enterTimeline.add(hpEnter());
    enterTimeline.eventCallback('onComplete', done);
  },
  onAfterEnter(el, done) {
    let afterEnterTimeline = gsap.timeline();
    afterEnterTimeline.add(hpAfterEnter());
    afterEnterTimeline.eventCallback('onComplete', done);
  },
};

const pageTransition = {
  name: 'page-transiton',
  mode: 'out-in',
  onBeforeLeave(el, done) {
    let beforeLeaveTimeline = gsap.timeline();
    beforeLeaveTimeline.add(nothing());
    beforeLeaveTimeline.eventCallback('onComplete', done);
  },
  onLeave(el, done) {
    let leaveTimeline = gsap.timeline();
    leaveTimeline.add(pageLeave());
    leaveTimeline.eventCallback('onComplete', done);
  },
  onAfterLeave(el, done) {
    let afterLeaveTimeline = gsap.timeline();
    afterLeaveTimeline.add(nothing());
    afterLeaveTimeline.eventCallback('onComplete', done);
  },
  onBeforeEnter(el, done) {
    let enterTimeline = gsap.timeline();
    enterTimeline.add(pageEnter());
    enterTimeline.eventCallback('onComplete', done);
  },
  onAfterEnter(el, done) {
    let afterEnterTimeline = gsap.timeline();
    afterEnterTimeline.add(nothing());
    afterEnterTimeline.eventCallback('onComplete', done);
  },
};

export { homepageTransition, pageTransition };
