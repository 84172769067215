export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, viewport-fit=cover"},{"name":"author","content":"Brainz Disruptive"},{"name":"format-detection","content":"telephone=no"},{"name":"description","content":"Nejstarší kulturní instituce v ČR, která spravuje největší a nejvýznamnější uměleckou sbírku na našem území."},{"hid":"og:url","property":"og:url","content":"https://www.ngprague.cz/"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:title","property":"og:title","content":"Národní galerie Praha"},{"hid":"og:description","property":"og:description","content":"Nejstarší kulturní instituce v ČR, která spravuje největší a nejvýznamnější uměleckou sbírku na našem území."},{"hid":"og:image","property":"og:image","content":"https://www.ngprague.cz/img/og-image.jpeg"},{"hid":"og:site_name","property":"og:site_name","content":"Národní galerie Praha"},{"hid":"twitter:domain","property":"twitter:domain","content":"ngprague.cz"},{"hid":"twitter:url","property":"twitter:url","content":"https://www.ngprague.cz/"},{"hid":"twitter:card","property":"twitter:card","content":"summary_large_image"},{"hid":"twitter:title","property":"twitter:title","content":"Národní galerie Praha"},{"hid":"twitter:description","property":"twitter:description","content":"Nejstarší kulturní instituce v ČR, která spravuje největší a nejvýznamnější uměleckou sbírku na našem území."},{"hid":"twitter:image","property":"twitter:image","content":"/img/og-image.jpeg"}],"link":[{"rel":"stylesheet","href":"https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"cs"}}

export const appScript = [{"src":"https://cdn-cookieyes.com/client_data/9922cd1f6987a2a33694f7ba/script.js","id":"cookieyes"}]

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"