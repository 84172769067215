<script setup>
import { inject } from 'vue';
import { ref, computed, onMounted } from 'vue';
import moment from 'moment';
import { sortDates } from '../utils';

const props = defineProps({
  date: {
    type: Object,
    required: true,
  },
  inactive: {
    type: Boolean,
    default: false,
  },
  selectedDates: {
    type: Array,
    required: true,
  },
  focusedDate: {
    type: Object,
    default: null,
  },
});

const datepicker = ref(null);
const mouseLeft = ref(false);

// Find the nearest parent datepicker component
onMounted(() => {
  let component = getCurrentInstance();
  while (component && !datepicker.value) {
    if (component.type.name === 'DatePicker') {
      datepicker.value = component;
    }
    component = component.parent;
  }
});

const inRange = computed(() => {
  if (!props.selectedDates) return false;
  const dates = sortDates([...props.selectedDates]);
  return dates.length === 2 && props.date.isBetween(dates[0], dates[1], '[]');
});

const isHighlighted = computed(() => {
  if (props.selectedDates.length !== 1 || !props.focusedDate) return false;
  const dates = sortDates([props.selectedDates[0], props.focusedDate]);
  return props.date.isBetween(dates[0], dates[1], null, '[]');
});

const isSelected = computed(() => {
  return props.selectedDates.some((x) => x.isSame(props.date, 'day'));
});

function select() {
  datepickerDayClick(props.date);
}

function focus() {
  datepickerdayFocus(props.date);
}

function onMouseDown() {
  mouseLeft.value = false;
  select();
}

function onMouseUp() {
  if (mouseLeft.value) select();
}

function onMouseLeave() {
  mouseLeft.value = true;
}

const datepickerDayClick = inject('datepicker-dayClick');
const datepickerdayFocus = inject('datepicker-dayFocus');
</script>

<template>
  <button
    class="datepicker-day"
    :class="{
      'is-inactive': props.inactive,
      'is-selected': isSelected,
      'in-range': inRange,
      'is-highlighted': isHighlighted,
    }"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
    @mouseleave="onMouseLeave"
    @focusout.prevent
    @keyup.enter="select"
    @mouseenter="focus"
    @focus="focus"
  >
    {{ moment(props.date).format('D') }}
  </button>
</template>

<style lang="scss">
@use '@scss/settings/colors.scss' as *;
@use '@scss/tools/mixins.scss' as *;

.datepicker-day {
  cursor: pointer;
  height: 40px;
  font-size: 12px;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  @include button-reset;

  &.is-inactive {
    opacity: 0.4;
  }

  &.is-highlighted {
    background: $mercury;
  }

  &.in-range {
    background: #e5e5e5;
  }

  &:hover {
    background: $concrete;
    color: $black;
  }

  &.is-selected {
    background: $black;
    color: $white;

    &.is-inactive {
      opacity: 0.7;
    }
  }
}
</style>
