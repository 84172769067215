<script setup>
const { t } = useI18n();
const localePath = useLocalePath();
const props = defineProps({
  error: { type: Object, default: { statusCode: 500, message: '' } },
});
</script>
<template>
  <main class="simple">
    <div class="simple__center">
      <h1 class="heading">{{ t('global.error.error') }} {{ error.statusCode }}.</h1>
      <p class="text mt-4">
        <template v-if="error.statusCode == 404">
          {{ t('global.error.error-404') }}
        </template>
        <template v-else>
          {{ error.message }}
        </template>
      </p>
      <p class="text">
        <a :href="localePath('index')" class="link">
          {{ t('global.error.back-to-hp') }}
        </a>
      </p>
    </div>
    <img src="/img/logo-white.svg" class="simple__logo" />
  </main>
</template>
<style lang="scss" scoped>
@use '@scss/settings/colors.scss' as *;

.simple {
  align-items: center;
  background: $sienna;
  color: $white;
  display: flex;
  min-height: 100vh;
  position: relative;
  padding-bottom: 300px;
  padding-top: 40px;
  width: 100vw;
  &__center {
    margin-left: auto;
    margin-right: auto;
  }
  &__logo {
    bottom: 40px;
    left: 40px;
    max-width: 180px;
    position: absolute;
    width: 100%;
  }
}
</style>
