
// @ts-nocheck


export const localeCodes =  [
  "cs",
  "en"
]

export const localeLoaders = {
  "cs": [{ key: "../locales/cs.js", load: () => import("../locales/cs.js?hash=86883749&locale=cs" /* webpackChunkName: "locale__app_locales_cs_js" */), cache: false }],
  "en": [{ key: "../locales/en.js", load: () => import("../locales/en.js?hash=bde023b2&locale=en" /* webpackChunkName: "locale__app_locales_en_js" */), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "jsTsFormatResource": true
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "cs",
      "language": "cs-CZ",
      "name": "CZ",
      "files": [
        "/app/locales/cs.js"
      ]
    },
    {
      "code": "en",
      "language": "en-US",
      "name": "EN",
      "files": [
        "/app/locales/en.js"
      ]
    }
  ],
  "defaultLocale": "cs",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "index": {
      "cs": "/",
      "en": "/"
    },
    "about/accessibility": {
      "cs": "/o-nas/pristupnost-budov",
      "en": "/about/accessibility"
    },
    "about/admission": {
      "cs": "/o-nas/vstupne-a-objednavky-akci",
      "en": "/about/admission"
    },
    "about/buildings/index": {
      "cs": "/o-nas/budovy",
      "en": "/about/buildings"
    },
    "about/buildings/[slug]": {
      "cs": "/o-nas/budovy/[slug]",
      "en": "/about/buildings/[slug]"
    },
    "about/bulletin": {
      "cs": "/o-nas/bulletin-ngp",
      "en": "/about/bulletin-ngp"
    },
    "about/collections/index": {
      "cs": "/o-nas/sbirky",
      "en": "/about/collections"
    },
    "about/collections/[slug]": {
      "cs": "/o-nas/sbirky/[slug]",
      "en": "/about/collections/[slug]"
    },
    "about/contact": {
      "cs": "/o-nas/kontaktni-informace",
      "en": "/about/contact"
    },
    "about/funds": {
      "cs": "/o-nas/fondy-a-sbirky",
      "en": "/about/funds-and-collections"
    },
    "about/library-books": {
      "cs": "/o-nas/knihy",
      "en": "/about/books"
    },
    "about/library-periodicals": {
      "cs": "/o-nas/periodika",
      "en": "/about/periodicals"
    },
    "about/index": {
      "cs": "/o-nas",
      "en": "/about"
    },
    "about/media": {
      "cs": "/o-nas/pro-media",
      "en": "/about/media"
    },
    "about/news/[slug]": {
      "cs": "/o-nas/novinky/[slug]",
      "en": "/about/news/[slug]"
    },
    "about/news/index": {
      "cs": "/o-nas/novinky",
      "en": "/about/news"
    },
    "about/page/[slug]": {
      "cs": "/o-nas/stranka/[slug]",
      "en": "/about/page/[slug]"
    },
    "about/partners/index": {
      "cs": "/o-nas/partneri",
      "en": "/about/partners"
    },
    "about/partners/[slug]": {
      "cs": "/o-nas/partneri/[slug]",
      "en": "/about/partneri/[slug]"
    },
    "about/people/index": {
      "cs": "/o-nas/lide",
      "en": "/about/people"
    },
    "about/people/[id]/[slug]": {
      "cs": "/o-nas/lide/[id]/[slug]",
      "en": "/about/people/[id]/[slug]"
    },
    "about/rent": {
      "cs": "/o-nas/stranka/pronajem-prostor",
      "en": "/about/page/rent"
    },
    "about/reasons": {
      "cs": "/o-nas/10-duvodu-k-navsteve",
      "en": "/about/10-reasons-to-visit"
    },
    "about/scientific": {
      "cs": "/o-nas/odborne-aktivity",
      "en": "/about/scientific-activity"
    },
    "about/support/index": {
      "cs": "/o-nas/podporte-ngp",
      "en": "/about/support-ngp"
    },
    "about/support/corporate": {
      "cs": "/o-nas/podporte-ngp/firmy-a-nadace",
      "en": "/about/support-ngp/corporate-donors"
    },
    "about/support/patrons": {
      "cs": "/o-nas/podporte-ngp/klub-patronu",
      "en": "/about/support-ngp/patrons-club"
    },
    "about/support/private": {
      "cs": "/o-nas/podporte-ngp/soukromi-darci",
      "en": "/about/support-ngp/private-donors"
    },
    "audioguide/[slug]": {
      "cs": "/audioguide/[slug]",
      "en": "/audioguide/[slug]"
    },
    "club/index": {
      "cs": "/klub-pratel-ngp",
      "en": "/club-of-friends"
    },
    "club/buy/[slug]": {
      "cs": "/klub-pratel-ngp/nakup/[slug]",
      "en": "/club-of-friends/buy/[slug]"
    },
    "club/thank-you-online": {
      "cs": "/klub-pratel-ngp/online/dekujeme",
      "en": "/club-of-friends/online/thank-you"
    },
    "club/thank-you-cash": {
      "cs": "/klub-pratel-ngp/hotove/dekujeme",
      "en": "/club-of-friends/cash/thank-you"
    },
    "club/thank-you-bank-transfer": {
      "cs": "/klub-pratel-ngp/banka/dekujeme",
      "en": "/club-of-friends/bank/thank-you"
    },
    "education/index": {
      "cs": "/vzdelavaci-programy",
      "en": "/education"
    },
    "education/adults": {
      "cs": "/vzdelavaci-programy/pro-dospele",
      "en": "/education/adults"
    },
    "education/courses": {
      "cs": "/vzdelavaci-programy/pro-dospele/kurzy-dejin-umeni",
      "en": "/education/adults/art-history-courses"
    },
    "education/games": {
      "cs": "/vzdelavaci-programy/pro-deti/herny",
      "en": "/education/kids/games"
    },
    "education/kids": {
      "cs": "/vzdelavaci-programy/pro-deti",
      "en": "/education/kids"
    },
    "education/kids-workshops": {
      "cs": "/vzdelavaci-programy/pro-deti/ateliery",
      "en": "/education/kids/studios"
    },
    "education/schools": {
      "cs": "/vzdelavaci-programy/pro-skolni-skupiny",
      "en": "/education/education-schools"
    },
    "education/special": {
      "cs": "/vzdelavaci-programy/pro-dospele/specialni-akce",
      "en": "/education/adults/special-events"
    },
    "education/summer-workshops": {
      "cs": "/vzdelavaci-programy/pro-deti/letni-ateliery",
      "en": "/education/kids/summer-studios"
    },
    "education/talks": {
      "cs": "/vzdelavaci-programy/pro-dospele/prednasky-a-diskuse",
      "en": "/education/adults/talks"
    },
    "education/teachers": {
      "cs": "/vzdelavaci-programy/pro-pedagogy",
      "en": "/education/teachers"
    },
    "education/teachers-archive": {
      "cs": "/vzdelavaci-programy/pro-pedagogy/studijni-materialy",
      "en": "/education/teachers/study-materials"
    },
    "education/tours": {
      "cs": "/vzdelavaci-programy/pro-dospele/komentovane-prohlidky",
      "en": "/education/adults/guided-tours"
    },
    "education/weekend-workshops": {
      "cs": "/vzdelavaci-programy/pro-deti/vikendove-dilny",
      "en": "/education/kids/weekend-workshops"
    },
    "education/workshops": {
      "cs": "/vzdelavaci-programy/pro-dospele/dilny-a-ateliery",
      "en": "/education/adults/workshops"
    },
    "event/[id]/[slug]/index": {
      "cs": "/udalost/[id]/[slug]",
      "en": "/event/[id]/[slug]"
    },
    "event/[id]/[slug]/[dateId]": {
      "cs": "/udalost/[id]/[slug]/[dateId]",
      "en": "/event/[id]/[slug]/[dateId]"
    },
    "event/[id]/[slug]/[ticketId]/tickets": {
      "cs": "/udalost/[id]/[slug]/[ticketId]/vstupenky",
      "en": "/event/[id]/[slug]/[ticketId]/tickets"
    },
    "events/[category]/[time]/[building]/index": {
      "cs": "/vystavy-a-akce/[category]/[time]/[building]",
      "en": "/exhibitions-and-events/[category]/[time]/[building]"
    },
    "events/[category]/[time]/index": {
      "cs": "/vystavy-a-akce/[category]/[time]",
      "en": "/exhibitions-and-events/[category]/[time]"
    },
    "events/[category]/index": {
      "cs": "/vystavy-a-akce/[category]",
      "en": "/exhibitions-and-events/[category]"
    },
    "events/podcast": {
      "cs": "/podcast",
      "en": "/podcast"
    },
    "events/index": {
      "cs": "/vystavy-a-akce",
      "en": "/exhibitions-and-events"
    },
    "newsletter/index": {
      "cs": "/odber-novinek",
      "en": "/newsletter"
    },
    "videos/index": {
      "cs": "/o-nas/videogalerie",
      "en": "/about/videogallery"
    },
    "videos/[category]/index": {
      "cs": "/o-nas/videogalerie/[category]",
      "en": "/about/videogallery/[category]"
    },
    "videos/[category]/[id]/index": {
      "cs": "/o-nas/videogalerie/[category]/[id]",
      "en": "/about/videogallery/[category]/[id]"
    },
    "[slug]": {
      "cs": "/[slug]",
      "en": "/[slug]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "seo": true,
  "legacy": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "cs",
    "language": "cs-CZ",
    "name": "CZ",
    "files": [
      {
        "path": "/app/locales/cs.js"
      }
    ]
  },
  {
    "code": "en",
    "language": "en-US",
    "name": "EN",
    "files": [
      {
        "path": "/app/locales/en.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
