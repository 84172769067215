<script setup>
const props = defineProps(['item']);

const { locale, t } = useI18n();

const store = useEventsStore();
const activeDropDownItem = computed(() => store.activeDropDownItem);
const activeTabItem = computed(() => store.activeTabItem);
const activeTabByDate = computed(() => store.activeTabByDate);
const activeTabByDateString = computed(() => store.activeTabByDateString);
const { setActiveTabByDate, setActiveTabByDateString } = store;

function convertDateFormat(stringA) {
  const dates = stringA.split('|');
  const [startYear, startMonth, startDay] = dates[0].split('-');
  const [endYear, endMonth, endDay] = dates[1].split('-');
  // Remove zeros if present
  const formattedStartDay = parseInt(startDay, 10).toString();
  const formattedEndDay = parseInt(endDay, 10).toString();
  const formattedStartMonth = parseInt(startMonth, 10).toString();
  const formattedEndMonth = parseInt(endMonth, 10).toString();

  const newStringA = `${formattedStartDay}/${formattedStartMonth}—${formattedEndDay}/${formattedEndMonth} ${endYear}`;
  return newStringA;
}

const activate = (item) => {
  setActiveTabByDate(item);
  setActiveTabByDateString(item.filter);
  const hasCategory = () => (activeTabItem.value.slug ? `/${activeTabItem.value.slug[locale.value]}` : '');
  const hasDate = () => (activeTabByDate.value.slug ? `/${activeTabByDate.value.slug[locale.value]}` : '');
  const hasBuilding = () => (activeDropDownItem.value ? `/${useSlugify(activeDropDownItem.value.name)}` : '');
  const currentPath = `/${locale.value === 'en' ? 'en/' : ''}${useSlugify(t('pages.events.breadcrumbs-title'))}`;
  history.pushState({}, null, currentPath + hasCategory() + hasDate() + hasBuilding());
};
</script>

<template>
  <button :id="item.id" class="tabs__button" @click="activate(item)" @keyup.enter="activate(item)" :data-text="item.name">
    {{ item.datepicker ? (activeTabByDateString?.includes('|') ? convertDateFormat(activeTabByDateString) : item.name) : item.name }}
  </button>
</template>

<style lang="scss">
@use '@scss/generic/forms/button';

.tabs {
  $s: &;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @include mq($md) {
    border-bottom: 1px solid $black;
  }

  &__line {
    background: $black;
    bottom: 0;
    display: none;
    left: 0;
    height: 2px;
    position: absolute;
    transition:
      transform 300ms,
      width 300ms;
    width: 0;

    @include mq($md) {
      display: block;
    }
  }

  &__item {
    margin-right: 25px;

    @include mq($xxl) {
      margin-right: 50px;
    }

    &--end {
      margin-left: auto;
      margin-right: 0;
    }
  }

  &__button {
    @include button-reset;
    padding: 10px 0;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    background: linear-gradient(to top, $black 0%, $black 100%) center bottom no-repeat;
    background-size: 100% 0px;
    //transition: font-weight 300ms;
    white-space: nowrap;

    &:before {
      display: block;
      content: attr(data-text);
      font-weight: $font-weight-medium;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &:hover {
      //font-weight: $font-weight-medium;
    }

    &:focus {
      outline: none;
      //background-size: 100% 1px;
    }

    #{$s}__item.is-active & {
      font-weight: $font-weight-medium;
      //background-size: 100% 2px;

      /*
            #{$s}__arrow {
                transform: rotate(180deg);
            }
            */
    }
  }

  &__border {
    @extend .button;
    @extend .button--small;
    @extend .button--normal;
    @extend .button--transparent;

    #{$s}__arrow {
      border-width: 4px;
      border-bottom-width: 0;
      margin-left: 8px;
    }
  }

  &__arrow {
    @include triangle(currentColor, down, 5px);
    display: inline-block;
    vertical-align: middle;
    transition: transform 300ms;
  }
}
</style>
