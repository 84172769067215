export function useScreenWidthListener() {
  const timeoutId = ref(null);
  const screenWidth = ref(0);

  onBeforeMount(() => {
    screenWidth.value = window.innerWidth;
  });

  onMounted(() => {
    window.addEventListener('resize', handleResize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });

  const handleResize = () => {
    clearTimeout(timeoutId.value);
    timeoutId.value = setTimeout(() => {
      screenWidth.value = window.innerWidth;
    }, 350);
  };

  return { screenWidth };
}
