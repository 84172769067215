<template>
  <div class="datepicker-pane">
    <div class="datepicker-pane__header">{{ header }}</div>
    <div class="datepicker-pane__names">
      <!-- Previous month days -->
      <div v-for="day in numberOfPrevDays" class="datepicker-pane__day datepicker-pane__day--other">
        <div class="datepicker-name">
          {{
            moment(startMoment)
              .subtract(numberOfPrevDays - day + 1, 'days')
              .locale($i18n.locale)
              .format('dd')
          }}
        </div>
      </div>
      <!-- Current month days -->
      <div v-for="(day, index) in daysInMonth" :key="index">
        <div v-if="index < 7 - numberOfPrevDays" class="datepicker-pane__day">
          <div class="datepicker-name">
            {{
              moment(startMoment)
                .add(day - 1, 'days')
                .locale($i18n.locale)
                .format('dd')
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="datepicker-pane__main">
      <!-- Previous month days -->
      <div v-for="day in numberOfPrevDays" :key="`prevMonthDay-${day}`" class="datepicker-pane__day datepicker-pane__day--other">
        <Day :date="moment(startMoment).subtract(numberOfPrevDays - day + 1, 'days')" :selected-dates="selectedDates" :focused-date="focusedDate" inactive />
      </div>
      <!-- Current month days -->
      <div v-for="day in daysInMonth" :key="`day-${day}`" class="datepicker-pane__day">
        <!-- A -->
        <!-- <Day>{{ day }}</Day> -->
        <Day :date="moment(startMoment).add(day - 1, 'days')" :selected-dates="selectedDates" :focused-date="focusedDate" />
      </div>
      <!-- Next month days -->
      <div v-for="day in numberOfNextDays" :key="`nextMonthDay-${day}`" class="datepicker-pane__day datepicker-pane__day--other">
        <Day :date="moment(endMoment).add(day, 'days')" :selected-dates="selectedDates" :focused-date="focusedDate" inactive />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Day from './Day.vue';

export default {
  components: {
    Day,
  },
  props: {
    date: {
      type: Object,
      required: true,
    },
    selectedDates: {
      type: Array,
      required: true,
    },
    focusedDate: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: true,
    },
  },
  computed: {
    /**
     * Header text
     * @returns {String}
     */
    header() {
      return this.date.locale(this.$i18n.locale).format('MMMM YYYY');
    },
    /**
     * Moment object for first day of month
     * @returns {Object}
     */
    startMoment() {
      return this.date.startOf('month');
    },
    /**
     * Moment object for last day of month
     * @returns {Object}
     */
    endMoment() {
      return this.date.endOf('month');
    },
    /**
     * Get number of days in current month
     * @return {Number}
     */
    daysInMonth() {
      return this.date.daysInMonth();
    },
    /**
     * Get weekday for first day in current month
     * @return {Number}
     */
    firstWeekDay() {
      return this.date.startOf('month').day();
    },
    /**
     * Get number of days in previous month
     * @return {Number}
     */
    daysInPreviousMonth() {
      return this.moment(this.date).subtract(1, 'month').daysInMonth();
    },
    /**
     * Get number of visible (inactive) days of previous month
     * @return {Number}
     */
    numberOfPrevDays() {
      return Math.max(0, this.firstWeekDay - 1);
    },
    /**
     * Get number of visible (inactive) days of next month
     * @return {Number}
     */
    numberOfNextDays() {
      const modulo = (this.daysInMonth + this.numberOfPrevDays) % 7;
      return modulo > 0 ? 7 - modulo : 0;
    },
  },
  methods: {
    moment,
  },
};
</script>

<style lang="scss">
.datepicker-pane {
  &__header {
    text-transform: uppercase;
    text-align: center;
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(7, 40px);
  }

  &__names {
    display: grid;
    grid-template-columns: repeat(7, 40px);
    margin-top: 20px;
  }
}
.datepicker-name {
  height: 30px;
  font-size: 10px;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
</style>
