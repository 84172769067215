import { default as _91slug_93msfiAekumQMeta } from "/app/pages/[slug].vue?macro=true";
import { default as accessibilityjpX0FW9T2YMeta } from "/app/pages/about/accessibility.vue?macro=true";
import { default as admission0KChaVjZ0kMeta } from "/app/pages/about/admission.vue?macro=true";
import { default as _91slug_93iVqs7qDgMyMeta } from "/app/pages/about/buildings/[slug].vue?macro=true";
import { default as index5aBG7D1rpJMeta } from "/app/pages/about/buildings/index.vue?macro=true";
import { default as bulletinGgIv08XUlBMeta } from "/app/pages/about/bulletin.vue?macro=true";
import { default as _91slug_939Ndy3Q9UQRMeta } from "/app/pages/about/collections/[slug].vue?macro=true";
import { default as indexJJgCsYhAHeMeta } from "/app/pages/about/collections/index.vue?macro=true";
import { default as contactmvCf4p76raMeta } from "/app/pages/about/contact.vue?macro=true";
import { default as fundsUthKrjfnA8Meta } from "/app/pages/about/funds.vue?macro=true";
import { default as indexTRqRpIOxLQMeta } from "/app/pages/about/index.vue?macro=true";
import { default as library_45bookscvKLsTf5puMeta } from "/app/pages/about/library-books.vue?macro=true";
import { default as library_45periodicalsBQsfpLdMzMMeta } from "/app/pages/about/library-periodicals.vue?macro=true";
import { default as _91slug_93CdOnNYJXAoMeta } from "/app/pages/about/news/[slug].vue?macro=true";
import { default as index5aUJ1gNkb6Meta } from "/app/pages/about/news/index.vue?macro=true";
import { default as _91slug_932JTYkgOaTYMeta } from "/app/pages/about/page/[slug].vue?macro=true";
import { default as _91slug_93rQcfRlqBpzMeta } from "/app/pages/about/partners/[slug].vue?macro=true";
import { default as indexAfj5ehTX2TMeta } from "/app/pages/about/partners/index.vue?macro=true";
import { default as _91slug_93zBOKYlNE9HMeta } from "/app/pages/about/people/[id]/[slug].vue?macro=true";
import { default as indexY5VKBfcmxMMeta } from "/app/pages/about/people/index.vue?macro=true";
import { default as reasonsKGURbmgkaoMeta } from "/app/pages/about/reasons.vue?macro=true";
import { default as rent7iwYRl3NDsMeta } from "/app/pages/about/rent.vue?macro=true";
import { default as scientific7PEBwCrQ3sMeta } from "/app/pages/about/scientific.vue?macro=true";
import { default as corporate6hf8VR6GFnMeta } from "/app/pages/about/support/corporate.vue?macro=true";
import { default as index3HRa9orul7Meta } from "/app/pages/about/support/index.vue?macro=true";
import { default as patronsPNyPkKKQ3nMeta } from "/app/pages/about/support/patrons.vue?macro=true";
import { default as privatees9XLppepLMeta } from "/app/pages/about/support/private.vue?macro=true";
import { default as _91slug_93kAGlO0BYd8Meta } from "/app/pages/audioguide/[slug].vue?macro=true";
import { default as _91slug_93nz18L9dO0yMeta } from "/app/pages/club/buy/[slug].vue?macro=true";
import { default as indexWXlDNwQzlsMeta } from "/app/pages/club/index.vue?macro=true";
import { default as thank_45you_45bank_45transferas7v0aOre5Meta } from "/app/pages/club/thank-you-bank-transfer.vue?macro=true";
import { default as thank_45you_45cashGIZ5rAWxCjMeta } from "/app/pages/club/thank-you-cash.vue?macro=true";
import { default as thank_45you_45online1FnQcaOwKmMeta } from "/app/pages/club/thank-you-online.vue?macro=true";
import { default as adultssoQ2wdIaBDMeta } from "/app/pages/education/adults.vue?macro=true";
import { default as coursesWLkqKay7TyMeta } from "/app/pages/education/courses.vue?macro=true";
import { default as gamesELGLybCEWQMeta } from "/app/pages/education/games.vue?macro=true";
import { default as indexe8k8pComNzMeta } from "/app/pages/education/index.vue?macro=true";
import { default as kids_45workshopssluprXkE4MMeta } from "/app/pages/education/kids-workshops.vue?macro=true";
import { default as kidsC3oVErcPqJMeta } from "/app/pages/education/kids.vue?macro=true";
import { default as schoolsIIa65S1BLhMeta } from "/app/pages/education/schools.vue?macro=true";
import { default as specialjp0DQr6ToWMeta } from "/app/pages/education/special.vue?macro=true";
import { default as summer_45workshopsfodGoAfLYoMeta } from "/app/pages/education/summer-workshops.vue?macro=true";
import { default as talks8snj8s7YJRMeta } from "/app/pages/education/talks.vue?macro=true";
import { default as teachers_45archiveiPRcC8nQKPMeta } from "/app/pages/education/teachers-archive.vue?macro=true";
import { default as teachersYW3NkwHwL0Meta } from "/app/pages/education/teachers.vue?macro=true";
import { default as tours2vImVmo7lQMeta } from "/app/pages/education/tours.vue?macro=true";
import { default as weekend_45workshops3oaY7hG8QFMeta } from "/app/pages/education/weekend-workshops.vue?macro=true";
import { default as workshopsgVzECACxOlMeta } from "/app/pages/education/workshops.vue?macro=true";
import { default as _91dateId_93ZXNB4utrX0Meta } from "/app/pages/event/[id]/[slug]/[dateId].vue?macro=true";
import { default as ticketscBDA4u9RqCMeta } from "/app/pages/event/[id]/[slug]/[ticketId]/tickets.vue?macro=true";
import { default as indexOmXcnyMjm7Meta } from "/app/pages/event/[id]/[slug]/index.vue?macro=true";
import { default as indexsMYhRT9iAuMeta } from "/app/pages/events/[category]/[time]/[building]/index.vue?macro=true";
import { default as indexqO7NHHzsRhMeta } from "/app/pages/events/[category]/[time]/index.vue?macro=true";
import { default as indexRwEBaOtlJHMeta } from "/app/pages/events/[category]/index.vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as podcastU4q7bGxRY5Meta } from "/app/pages/events/podcast.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as newsletterVEF8u8KZkVMeta } from "/app/pages/newsletter.vue?macro=true";
import { default as indexvbvin9kHKIMeta } from "/app/pages/videos/[category]/[id]/index.vue?macro=true";
import { default as indexLnE2H4ghrjMeta } from "/app/pages/videos/[category]/index.vue?macro=true";
import { default as indexvxwirc7LMiMeta } from "/app/pages/videos/index.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___cs",
    path: "/:slug()",
    meta: _91slug_93msfiAekumQMeta || {},
    component: () => import("/app/pages/[slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug()",
    meta: _91slug_93msfiAekumQMeta || {},
    component: () => import("/app/pages/[slug].vue")
  },
  {
    name: "about-accessibility___cs",
    path: "/o-nas/pristupnost-budov",
    meta: accessibilityjpX0FW9T2YMeta || {},
    component: () => import("/app/pages/about/accessibility.vue")
  },
  {
    name: "about-accessibility___en",
    path: "/en/about/accessibility",
    meta: accessibilityjpX0FW9T2YMeta || {},
    component: () => import("/app/pages/about/accessibility.vue")
  },
  {
    name: "about-admission___cs",
    path: "/o-nas/vstupne-a-objednavky-akci",
    meta: admission0KChaVjZ0kMeta || {},
    component: () => import("/app/pages/about/admission.vue")
  },
  {
    name: "about-admission___en",
    path: "/en/about/admission",
    meta: admission0KChaVjZ0kMeta || {},
    component: () => import("/app/pages/about/admission.vue")
  },
  {
    name: "about-buildings-slug___cs",
    path: "/o-nas/budovy/:slug()",
    meta: _91slug_93iVqs7qDgMyMeta || {},
    component: () => import("/app/pages/about/buildings/[slug].vue")
  },
  {
    name: "about-buildings-slug___en",
    path: "/en/about/buildings/:slug()",
    meta: _91slug_93iVqs7qDgMyMeta || {},
    component: () => import("/app/pages/about/buildings/[slug].vue")
  },
  {
    name: "about-buildings___cs",
    path: "/o-nas/budovy",
    meta: index5aBG7D1rpJMeta || {},
    component: () => import("/app/pages/about/buildings/index.vue")
  },
  {
    name: "about-buildings___en",
    path: "/en/about/buildings",
    meta: index5aBG7D1rpJMeta || {},
    component: () => import("/app/pages/about/buildings/index.vue")
  },
  {
    name: "about-bulletin___cs",
    path: "/o-nas/bulletin-ngp",
    meta: bulletinGgIv08XUlBMeta || {},
    component: () => import("/app/pages/about/bulletin.vue")
  },
  {
    name: "about-bulletin___en",
    path: "/en/about/bulletin-ngp",
    meta: bulletinGgIv08XUlBMeta || {},
    component: () => import("/app/pages/about/bulletin.vue")
  },
  {
    name: "about-collections-slug___cs",
    path: "/o-nas/sbirky/:slug()",
    meta: _91slug_939Ndy3Q9UQRMeta || {},
    component: () => import("/app/pages/about/collections/[slug].vue")
  },
  {
    name: "about-collections-slug___en",
    path: "/en/about/collections/:slug()",
    meta: _91slug_939Ndy3Q9UQRMeta || {},
    component: () => import("/app/pages/about/collections/[slug].vue")
  },
  {
    name: "about-collections___cs",
    path: "/o-nas/sbirky",
    meta: indexJJgCsYhAHeMeta || {},
    component: () => import("/app/pages/about/collections/index.vue")
  },
  {
    name: "about-collections___en",
    path: "/en/about/collections",
    meta: indexJJgCsYhAHeMeta || {},
    component: () => import("/app/pages/about/collections/index.vue")
  },
  {
    name: "about-contact___cs",
    path: "/o-nas/kontaktni-informace",
    meta: contactmvCf4p76raMeta || {},
    component: () => import("/app/pages/about/contact.vue")
  },
  {
    name: "about-contact___en",
    path: "/en/about/contact",
    meta: contactmvCf4p76raMeta || {},
    component: () => import("/app/pages/about/contact.vue")
  },
  {
    name: "about-funds___cs",
    path: "/o-nas/fondy-a-sbirky",
    meta: fundsUthKrjfnA8Meta || {},
    component: () => import("/app/pages/about/funds.vue")
  },
  {
    name: "about-funds___en",
    path: "/en/about/funds-and-collections",
    meta: fundsUthKrjfnA8Meta || {},
    component: () => import("/app/pages/about/funds.vue")
  },
  {
    name: "about___cs",
    path: "/o-nas",
    meta: indexTRqRpIOxLQMeta || {},
    component: () => import("/app/pages/about/index.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    meta: indexTRqRpIOxLQMeta || {},
    component: () => import("/app/pages/about/index.vue")
  },
  {
    name: "about-library-books___cs",
    path: "/o-nas/knihy",
    meta: library_45bookscvKLsTf5puMeta || {},
    component: () => import("/app/pages/about/library-books.vue")
  },
  {
    name: "about-library-books___en",
    path: "/en/about/books",
    meta: library_45bookscvKLsTf5puMeta || {},
    component: () => import("/app/pages/about/library-books.vue")
  },
  {
    name: "about-library-periodicals___cs",
    path: "/o-nas/periodika",
    meta: library_45periodicalsBQsfpLdMzMMeta || {},
    component: () => import("/app/pages/about/library-periodicals.vue")
  },
  {
    name: "about-library-periodicals___en",
    path: "/en/about/periodicals",
    meta: library_45periodicalsBQsfpLdMzMMeta || {},
    component: () => import("/app/pages/about/library-periodicals.vue")
  },
  {
    name: "about-news-slug___cs",
    path: "/o-nas/novinky/:slug()",
    meta: _91slug_93CdOnNYJXAoMeta || {},
    component: () => import("/app/pages/about/news/[slug].vue")
  },
  {
    name: "about-news-slug___en",
    path: "/en/about/news/:slug()",
    meta: _91slug_93CdOnNYJXAoMeta || {},
    component: () => import("/app/pages/about/news/[slug].vue")
  },
  {
    name: "about-news___cs",
    path: "/o-nas/novinky",
    meta: index5aUJ1gNkb6Meta || {},
    component: () => import("/app/pages/about/news/index.vue")
  },
  {
    name: "about-news___en",
    path: "/en/about/news",
    meta: index5aUJ1gNkb6Meta || {},
    component: () => import("/app/pages/about/news/index.vue")
  },
  {
    name: "about-page-slug___cs",
    path: "/o-nas/stranka/:slug()",
    meta: _91slug_932JTYkgOaTYMeta || {},
    component: () => import("/app/pages/about/page/[slug].vue")
  },
  {
    name: "about-page-slug___en",
    path: "/en/about/page/:slug()",
    meta: _91slug_932JTYkgOaTYMeta || {},
    component: () => import("/app/pages/about/page/[slug].vue")
  },
  {
    name: "about-partners-slug___cs",
    path: "/o-nas/partneri/:slug()",
    meta: _91slug_93rQcfRlqBpzMeta || {},
    component: () => import("/app/pages/about/partners/[slug].vue")
  },
  {
    name: "about-partners-slug___en",
    path: "/en/about/partneri/:slug()",
    meta: _91slug_93rQcfRlqBpzMeta || {},
    component: () => import("/app/pages/about/partners/[slug].vue")
  },
  {
    name: "about-partners___cs",
    path: "/o-nas/partneri",
    meta: indexAfj5ehTX2TMeta || {},
    component: () => import("/app/pages/about/partners/index.vue")
  },
  {
    name: "about-partners___en",
    path: "/en/about/partners",
    meta: indexAfj5ehTX2TMeta || {},
    component: () => import("/app/pages/about/partners/index.vue")
  },
  {
    name: "about-people-id-slug___cs",
    path: "/o-nas/lide/:id()/:slug()",
    meta: _91slug_93zBOKYlNE9HMeta || {},
    component: () => import("/app/pages/about/people/[id]/[slug].vue")
  },
  {
    name: "about-people-id-slug___en",
    path: "/en/about/people/:id()/:slug()",
    meta: _91slug_93zBOKYlNE9HMeta || {},
    component: () => import("/app/pages/about/people/[id]/[slug].vue")
  },
  {
    name: "about-people___cs",
    path: "/o-nas/lide",
    meta: indexY5VKBfcmxMMeta || {},
    component: () => import("/app/pages/about/people/index.vue")
  },
  {
    name: "about-people___en",
    path: "/en/about/people",
    meta: indexY5VKBfcmxMMeta || {},
    component: () => import("/app/pages/about/people/index.vue")
  },
  {
    name: "about-reasons___cs",
    path: "/o-nas/10-duvodu-k-navsteve",
    meta: reasonsKGURbmgkaoMeta || {},
    component: () => import("/app/pages/about/reasons.vue")
  },
  {
    name: "about-reasons___en",
    path: "/en/about/10-reasons-to-visit",
    meta: reasonsKGURbmgkaoMeta || {},
    component: () => import("/app/pages/about/reasons.vue")
  },
  {
    name: "about-rent___cs",
    path: "/o-nas/stranka/pronajem-prostor",
    meta: rent7iwYRl3NDsMeta || {},
    component: () => import("/app/pages/about/rent.vue")
  },
  {
    name: "about-rent___en",
    path: "/en/about/page/rent",
    meta: rent7iwYRl3NDsMeta || {},
    component: () => import("/app/pages/about/rent.vue")
  },
  {
    name: "about-scientific___cs",
    path: "/o-nas/odborne-aktivity",
    meta: scientific7PEBwCrQ3sMeta || {},
    component: () => import("/app/pages/about/scientific.vue")
  },
  {
    name: "about-scientific___en",
    path: "/en/about/scientific-activity",
    meta: scientific7PEBwCrQ3sMeta || {},
    component: () => import("/app/pages/about/scientific.vue")
  },
  {
    name: "about-support-corporate___cs",
    path: "/o-nas/podporte-ngp/firmy-a-nadace",
    meta: corporate6hf8VR6GFnMeta || {},
    component: () => import("/app/pages/about/support/corporate.vue")
  },
  {
    name: "about-support-corporate___en",
    path: "/en/about/support-ngp/corporate-donors",
    meta: corporate6hf8VR6GFnMeta || {},
    component: () => import("/app/pages/about/support/corporate.vue")
  },
  {
    name: "about-support___cs",
    path: "/o-nas/podporte-ngp",
    meta: index3HRa9orul7Meta || {},
    component: () => import("/app/pages/about/support/index.vue")
  },
  {
    name: "about-support___en",
    path: "/en/about/support-ngp",
    meta: index3HRa9orul7Meta || {},
    component: () => import("/app/pages/about/support/index.vue")
  },
  {
    name: "about-support-patrons___cs",
    path: "/o-nas/podporte-ngp/klub-patronu",
    meta: patronsPNyPkKKQ3nMeta || {},
    component: () => import("/app/pages/about/support/patrons.vue")
  },
  {
    name: "about-support-patrons___en",
    path: "/en/about/support-ngp/patrons-club",
    meta: patronsPNyPkKKQ3nMeta || {},
    component: () => import("/app/pages/about/support/patrons.vue")
  },
  {
    name: "about-support-private___cs",
    path: "/o-nas/podporte-ngp/soukromi-darci",
    meta: privatees9XLppepLMeta || {},
    component: () => import("/app/pages/about/support/private.vue")
  },
  {
    name: "about-support-private___en",
    path: "/en/about/support-ngp/private-donors",
    meta: privatees9XLppepLMeta || {},
    component: () => import("/app/pages/about/support/private.vue")
  },
  {
    name: "audioguide-slug___cs",
    path: "/audioguide/:slug()",
    meta: _91slug_93kAGlO0BYd8Meta || {},
    component: () => import("/app/pages/audioguide/[slug].vue")
  },
  {
    name: "audioguide-slug___en",
    path: "/en/audioguide/:slug()",
    meta: _91slug_93kAGlO0BYd8Meta || {},
    component: () => import("/app/pages/audioguide/[slug].vue")
  },
  {
    name: "club-buy-slug___cs",
    path: "/klub-pratel-ngp/nakup/:slug()",
    meta: _91slug_93nz18L9dO0yMeta || {},
    component: () => import("/app/pages/club/buy/[slug].vue")
  },
  {
    name: "club-buy-slug___en",
    path: "/en/club-of-friends/buy/:slug()",
    meta: _91slug_93nz18L9dO0yMeta || {},
    component: () => import("/app/pages/club/buy/[slug].vue")
  },
  {
    name: "club___cs",
    path: "/klub-pratel-ngp",
    meta: indexWXlDNwQzlsMeta || {},
    component: () => import("/app/pages/club/index.vue")
  },
  {
    name: "club___en",
    path: "/en/club-of-friends",
    meta: indexWXlDNwQzlsMeta || {},
    component: () => import("/app/pages/club/index.vue")
  },
  {
    name: "club-thank-you-bank-transfer___cs",
    path: "/klub-pratel-ngp/banka/dekujeme",
    meta: thank_45you_45bank_45transferas7v0aOre5Meta || {},
    component: () => import("/app/pages/club/thank-you-bank-transfer.vue")
  },
  {
    name: "club-thank-you-bank-transfer___en",
    path: "/en/club-of-friends/bank/thank-you",
    meta: thank_45you_45bank_45transferas7v0aOre5Meta || {},
    component: () => import("/app/pages/club/thank-you-bank-transfer.vue")
  },
  {
    name: "club-thank-you-cash___cs",
    path: "/klub-pratel-ngp/hotove/dekujeme",
    meta: thank_45you_45cashGIZ5rAWxCjMeta || {},
    component: () => import("/app/pages/club/thank-you-cash.vue")
  },
  {
    name: "club-thank-you-cash___en",
    path: "/en/club-of-friends/cash/thank-you",
    meta: thank_45you_45cashGIZ5rAWxCjMeta || {},
    component: () => import("/app/pages/club/thank-you-cash.vue")
  },
  {
    name: "club-thank-you-online___cs",
    path: "/klub-pratel-ngp/online/dekujeme",
    meta: thank_45you_45online1FnQcaOwKmMeta || {},
    component: () => import("/app/pages/club/thank-you-online.vue")
  },
  {
    name: "club-thank-you-online___en",
    path: "/en/club-of-friends/online/thank-you",
    meta: thank_45you_45online1FnQcaOwKmMeta || {},
    component: () => import("/app/pages/club/thank-you-online.vue")
  },
  {
    name: "education-adults___cs",
    path: "/vzdelavaci-programy/pro-dospele",
    meta: adultssoQ2wdIaBDMeta || {},
    component: () => import("/app/pages/education/adults.vue")
  },
  {
    name: "education-adults___en",
    path: "/en/education/adults",
    meta: adultssoQ2wdIaBDMeta || {},
    component: () => import("/app/pages/education/adults.vue")
  },
  {
    name: "education-courses___cs",
    path: "/vzdelavaci-programy/pro-dospele/kurzy-dejin-umeni",
    meta: coursesWLkqKay7TyMeta || {},
    component: () => import("/app/pages/education/courses.vue")
  },
  {
    name: "education-courses___en",
    path: "/en/education/adults/art-history-courses",
    meta: coursesWLkqKay7TyMeta || {},
    component: () => import("/app/pages/education/courses.vue")
  },
  {
    name: "education-games___cs",
    path: "/vzdelavaci-programy/pro-deti/herny",
    meta: gamesELGLybCEWQMeta || {},
    component: () => import("/app/pages/education/games.vue")
  },
  {
    name: "education-games___en",
    path: "/en/education/kids/games",
    meta: gamesELGLybCEWQMeta || {},
    component: () => import("/app/pages/education/games.vue")
  },
  {
    name: "education___cs",
    path: "/vzdelavaci-programy",
    meta: indexe8k8pComNzMeta || {},
    component: () => import("/app/pages/education/index.vue")
  },
  {
    name: "education___en",
    path: "/en/education",
    meta: indexe8k8pComNzMeta || {},
    component: () => import("/app/pages/education/index.vue")
  },
  {
    name: "education-kids-workshops___cs",
    path: "/vzdelavaci-programy/pro-deti/ateliery",
    meta: kids_45workshopssluprXkE4MMeta || {},
    component: () => import("/app/pages/education/kids-workshops.vue")
  },
  {
    name: "education-kids-workshops___en",
    path: "/en/education/kids/studios",
    meta: kids_45workshopssluprXkE4MMeta || {},
    component: () => import("/app/pages/education/kids-workshops.vue")
  },
  {
    name: "education-kids___cs",
    path: "/vzdelavaci-programy/pro-deti",
    meta: kidsC3oVErcPqJMeta || {},
    component: () => import("/app/pages/education/kids.vue")
  },
  {
    name: "education-kids___en",
    path: "/en/education/kids",
    meta: kidsC3oVErcPqJMeta || {},
    component: () => import("/app/pages/education/kids.vue")
  },
  {
    name: "education-schools___cs",
    path: "/vzdelavaci-programy/pro-skolni-skupiny",
    meta: schoolsIIa65S1BLhMeta || {},
    component: () => import("/app/pages/education/schools.vue")
  },
  {
    name: "education-schools___en",
    path: "/en/education/education-schools",
    meta: schoolsIIa65S1BLhMeta || {},
    component: () => import("/app/pages/education/schools.vue")
  },
  {
    name: "education-special___cs",
    path: "/vzdelavaci-programy/pro-dospele/specialni-akce",
    meta: specialjp0DQr6ToWMeta || {},
    component: () => import("/app/pages/education/special.vue")
  },
  {
    name: "education-special___en",
    path: "/en/education/adults/special-events",
    meta: specialjp0DQr6ToWMeta || {},
    component: () => import("/app/pages/education/special.vue")
  },
  {
    name: "education-summer-workshops___cs",
    path: "/vzdelavaci-programy/pro-deti/letni-ateliery",
    meta: summer_45workshopsfodGoAfLYoMeta || {},
    component: () => import("/app/pages/education/summer-workshops.vue")
  },
  {
    name: "education-summer-workshops___en",
    path: "/en/education/kids/summer-studios",
    meta: summer_45workshopsfodGoAfLYoMeta || {},
    component: () => import("/app/pages/education/summer-workshops.vue")
  },
  {
    name: "education-talks___cs",
    path: "/vzdelavaci-programy/pro-dospele/prednasky-a-diskuse",
    meta: talks8snj8s7YJRMeta || {},
    component: () => import("/app/pages/education/talks.vue")
  },
  {
    name: "education-talks___en",
    path: "/en/education/adults/talks",
    meta: talks8snj8s7YJRMeta || {},
    component: () => import("/app/pages/education/talks.vue")
  },
  {
    name: "education-teachers-archive___cs",
    path: "/vzdelavaci-programy/pro-pedagogy/studijni-materialy",
    meta: teachers_45archiveiPRcC8nQKPMeta || {},
    component: () => import("/app/pages/education/teachers-archive.vue")
  },
  {
    name: "education-teachers-archive___en",
    path: "/en/education/teachers/study-materials",
    meta: teachers_45archiveiPRcC8nQKPMeta || {},
    component: () => import("/app/pages/education/teachers-archive.vue")
  },
  {
    name: "education-teachers___cs",
    path: "/vzdelavaci-programy/pro-pedagogy",
    meta: teachersYW3NkwHwL0Meta || {},
    component: () => import("/app/pages/education/teachers.vue")
  },
  {
    name: "education-teachers___en",
    path: "/en/education/teachers",
    meta: teachersYW3NkwHwL0Meta || {},
    component: () => import("/app/pages/education/teachers.vue")
  },
  {
    name: "education-tours___cs",
    path: "/vzdelavaci-programy/pro-dospele/komentovane-prohlidky",
    meta: tours2vImVmo7lQMeta || {},
    component: () => import("/app/pages/education/tours.vue")
  },
  {
    name: "education-tours___en",
    path: "/en/education/adults/guided-tours",
    meta: tours2vImVmo7lQMeta || {},
    component: () => import("/app/pages/education/tours.vue")
  },
  {
    name: "education-weekend-workshops___cs",
    path: "/vzdelavaci-programy/pro-deti/vikendove-dilny",
    meta: weekend_45workshops3oaY7hG8QFMeta || {},
    component: () => import("/app/pages/education/weekend-workshops.vue")
  },
  {
    name: "education-weekend-workshops___en",
    path: "/en/education/kids/weekend-workshops",
    meta: weekend_45workshops3oaY7hG8QFMeta || {},
    component: () => import("/app/pages/education/weekend-workshops.vue")
  },
  {
    name: "education-workshops___cs",
    path: "/vzdelavaci-programy/pro-dospele/dilny-a-ateliery",
    meta: workshopsgVzECACxOlMeta || {},
    component: () => import("/app/pages/education/workshops.vue")
  },
  {
    name: "education-workshops___en",
    path: "/en/education/adults/workshops",
    meta: workshopsgVzECACxOlMeta || {},
    component: () => import("/app/pages/education/workshops.vue")
  },
  {
    name: "event-id-slug-dateId___cs",
    path: "/udalost/:id()/:slug()/:dateId()",
    component: () => import("/app/pages/event/[id]/[slug]/[dateId].vue")
  },
  {
    name: "event-id-slug-dateId___en",
    path: "/en/event/:id()/:slug()/:dateId()",
    component: () => import("/app/pages/event/[id]/[slug]/[dateId].vue")
  },
  {
    name: "event-id-slug-ticketId-tickets___cs",
    path: "/udalost/:id()/:slug()/:ticketId()/vstupenky",
    meta: ticketscBDA4u9RqCMeta || {},
    component: () => import("/app/pages/event/[id]/[slug]/[ticketId]/tickets.vue")
  },
  {
    name: "event-id-slug-ticketId-tickets___en",
    path: "/en/event/:id()/:slug()/:ticketId()/tickets",
    meta: ticketscBDA4u9RqCMeta || {},
    component: () => import("/app/pages/event/[id]/[slug]/[ticketId]/tickets.vue")
  },
  {
    name: "event-id-slug___cs",
    path: "/udalost/:id()/:slug()",
    meta: indexOmXcnyMjm7Meta || {},
    component: () => import("/app/pages/event/[id]/[slug]/index.vue")
  },
  {
    name: "event-id-slug___en",
    path: "/en/event/:id()/:slug()",
    meta: indexOmXcnyMjm7Meta || {},
    component: () => import("/app/pages/event/[id]/[slug]/index.vue")
  },
  {
    name: "events-category-time-building___cs",
    path: "/vystavy-a-akce/:category()/:time()/:building()",
    meta: indexsMYhRT9iAuMeta || {},
    component: () => import("/app/pages/events/[category]/[time]/[building]/index.vue")
  },
  {
    name: "events-category-time-building___en",
    path: "/en/exhibitions-and-events/:category()/:time()/:building()",
    meta: indexsMYhRT9iAuMeta || {},
    component: () => import("/app/pages/events/[category]/[time]/[building]/index.vue")
  },
  {
    name: "events-category-time___cs",
    path: "/vystavy-a-akce/:category()/:time()",
    meta: indexqO7NHHzsRhMeta || {},
    component: () => import("/app/pages/events/[category]/[time]/index.vue")
  },
  {
    name: "events-category-time___en",
    path: "/en/exhibitions-and-events/:category()/:time()",
    meta: indexqO7NHHzsRhMeta || {},
    component: () => import("/app/pages/events/[category]/[time]/index.vue")
  },
  {
    name: "events-category___cs",
    path: "/vystavy-a-akce/:category()",
    meta: indexRwEBaOtlJHMeta || {},
    component: () => import("/app/pages/events/[category]/index.vue")
  },
  {
    name: "events-category___en",
    path: "/en/exhibitions-and-events/:category()",
    meta: indexRwEBaOtlJHMeta || {},
    component: () => import("/app/pages/events/[category]/index.vue")
  },
  {
    name: "events___cs",
    path: "/vystavy-a-akce",
    meta: indexWgIHVzns1AMeta || {},
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "events___en",
    path: "/en/exhibitions-and-events",
    meta: indexWgIHVzns1AMeta || {},
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "events-podcast___cs",
    path: "/podcast",
    meta: podcastU4q7bGxRY5Meta || {},
    component: () => import("/app/pages/events/podcast.vue")
  },
  {
    name: "events-podcast___en",
    path: "/en/podcast",
    meta: podcastU4q7bGxRY5Meta || {},
    component: () => import("/app/pages/events/podcast.vue")
  },
  {
    name: "index___cs",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "newsletter___cs",
    path: "/newsletter",
    meta: newsletterVEF8u8KZkVMeta || {},
    component: () => import("/app/pages/newsletter.vue")
  },
  {
    name: "newsletter___en",
    path: "/en/newsletter",
    meta: newsletterVEF8u8KZkVMeta || {},
    component: () => import("/app/pages/newsletter.vue")
  },
  {
    name: "videos-category-id___cs",
    path: "/o-nas/videogalerie/:category()/:id()",
    meta: indexvbvin9kHKIMeta || {},
    component: () => import("/app/pages/videos/[category]/[id]/index.vue")
  },
  {
    name: "videos-category-id___en",
    path: "/en/about/videogallery/:category()/:id()",
    meta: indexvbvin9kHKIMeta || {},
    component: () => import("/app/pages/videos/[category]/[id]/index.vue")
  },
  {
    name: "videos-category___cs",
    path: "/o-nas/videogalerie/:category()",
    meta: indexLnE2H4ghrjMeta || {},
    component: () => import("/app/pages/videos/[category]/index.vue")
  },
  {
    name: "videos-category___en",
    path: "/en/about/videogallery/:category()",
    meta: indexLnE2H4ghrjMeta || {},
    component: () => import("/app/pages/videos/[category]/index.vue")
  },
  {
    name: "videos___cs",
    path: "/o-nas/videogalerie",
    meta: indexvxwirc7LMiMeta || {},
    component: () => import("/app/pages/videos/index.vue")
  },
  {
    name: "videos___en",
    path: "/en/about/videogallery",
    meta: indexvxwirc7LMiMeta || {},
    component: () => import("/app/pages/videos/index.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]